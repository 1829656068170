import { Component, OnInit, Input, PLATFORM_ID, Inject } from '@angular/core';
import { AppSettings } from '../../../shared/app.settings';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-campaign-profile',
  templateUrl: './campaign-profile.component.html'
})
export class CampaignProfileComponent implements OnInit {
  @Input() profile :any;
  value = 100;
  videoUrl = '';
  ios = true;
  deviceMode = true;
  active = AppSettings.ACTIVE_CAMPAIGN;
  message = [];
  linkCopied = false;
  isPlatformBrowser: any;
  shareSms = false;
  description: any;


  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isPlatformBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (this.isPlatformBrowser) {
      this.deviceMode = this.detectmob();
    }
  }

  detectmob() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }



  linkCopiedMsg() {
    this.linkCopied = true;
    setTimeout(() => {
      this.linkCopied = false;
    }, 2000);
  }

  getDescription() {
    return `Please support our fundraiser for ${this.profile.name}! Click on the below link to contribute now.`;
  }

  getSMSBody(shareUrl:any) {
    if (this.isPlatformBrowser) {
      const userAgent = navigator.userAgent || navigator.vendor;
      const a: any = document.createElement('a');
      const link = encodeURIComponent(shareUrl);

      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
        a.href = `sms:&body=Please support our fundraiser for ${this.profile.name}! Click on the below link to contribute now. ${link}`;
        a.click();
      } else {
        a.href = `sms:?body=Please support our fundraiser for ${this.profile.name}! Click on the below link to contribute now. ${link}`;
        a.click();
      }
    }
  }
}

