<div *ngIf="user" class="overflow-hidden">
    <div class="campaign-wrapper" *ngIf="user.role == 'student'">
        <div class="div-center">
            <img [src]="user.profileImage == '' || user.profileImage == null || user.profileImage == undefined ? commonService.buildAwsImgLink(user.studentDetails[0].studentDefaultProfileLogoId) : commonService.buildAwsImgLink(user.profileImage)" class="donor-img" alt="">
            <div class="text-wrap inline">
                <h1 class="name">{{user.fullName}}</h1>
                <!-- <p class="para">#{{user.jerseyNum}} {{ this.user.teamPosition }}</p> -->
                <p class="para">{{ this.user.teamPosition }}</p>
            </div>
        </div>
    </div>
    <h1 class="heading text-center" *ngIf="campaign">{{campaign.name}}</h1>
        <div class="grid" *ngIf="campaign">
                <div class="col-4 md:col-4 lg:col-4 sm:col-12 pad-left">
                    <img [src]="campaign.coach.profileImage" class="prof-img pull-left ml-20" alt="">
                    <div class="inline m10">
                        <h3 class="mr-zero c-name">{{campaign.coach.fullName}}</h3>
                    </div>
                </div>
                <div class="col-1 md:col-1 lg:col-1 hide-col-reponsive"></div>
                <div class="col-5 md:col-5 lg:col-5 sm:col-12 ">
                    <p class="para mr-zero ml-20" [innerHTML]="campaign.details"></p>
                </div>
        </div>
</div>