import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserCommonService } from '../../../shared/services/user-common.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html'
})
export class SuccessComponent implements OnInit {
  transactionId :any;
  userId: any;
  campaignId: any;
  mediaType: any;

  constructor(private route: ActivatedRoute, private router: Router, private commonService: UserCommonService) { }

  ngOnInit() {
    this.transactionId = this.route.snapshot.queryParamMap.get('transactionId');
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.campaignId = this.route.snapshot.params['c'];
    this.mediaType = this.route.snapshot.queryParamMap.get('mediaType');
    localStorage.removeItem("currentUser");
  }

  onBack() {
    this.commonService.donor = '';
    this.router.navigate(['/donation/' + this.campaignId], {
      queryParams: {
        userId: this.userId,
        mediaType: this.mediaType
      }
    });
  }

}
