<div class="grid content-align">
  <div class="col-12 md:col-12 sm:col-12 lg:col-12 text-center">
    <div class="logo margin-top">
      <a href="http://www.teamfunded.com/" target="_blank">
        <img src="/assets/logo.png" />
      </a>
    </div>
  </div>
  <div class="col-12 md:col-12 sm:col-12 lg:col-12 text-center receipt">
    <p class="tittle">Receipt for TeamFunded</p>
  </div>

  <div class="col-12 md:col-12 sm:col-12 lg:col-12 text-size">
    This receipt is to certify that <strong *ngIf="receiptData.donorName !== 'Anonymous'">{{receiptData.donorName}}</strong> <strong *ngIf="receiptData.donorName === 'Anonymous'"> {{receiptData.donorEmail}} </strong> <strong *ngIf="receiptData.donorName !== 'Anonymous' && receiptData.donorEmail !== ''"> <{{receiptData.donorEmail}}> </strong> has successfully
    made a donation of <strong>${{receiptData.donationAmount}}</strong> to <strong>{{receiptData.campaignName}}</strong> for
    <strong>{{receiptData.schoolName}}</strong> <span *ngIf="receiptData.doneeName !== 'Admin User'"> on behalf of <strong>{{receiptData.doneeName}}</strong></span>.
  </div>

  <div class="col-12 md:col-12 sm:col-12 lg:col-12 mt-20">
    <p-table [value]="products" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>Donated Amount</td>
          <td class="col1"><strong>${{ product.donationAmount | number : '1.2-2' }}</strong></td>
         
        </tr>
        <tr>
          <td>Transaction Charges</td>
          <td class="col1"><strong>${{ product.donationFees | number : '1.2-2' }}</strong></td>
        </tr>
        <tr>
          <td>Total Amount Charged</td>
          <td class="col1"><strong>${{product.donationAmount+ +product.donationFees | number : '1.2-2' }}</strong></td>
        </tr>
        <tr>
          <td>Date Paid</td>
          <td class="col1"><strong>{{ product.donationDateTime | date: 'MMM d, y' }}</strong></td>
        </tr>
        <tr>
          <td>Payment Method</td>
          <td class="col1"><strong>{{product.paymentMethod}} - {{product.lastFourCardDigits}}</strong></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="col-12 md:col-12 sm:col-12 lg:col-12 para-content">
      <p>Please retain this receipt for your tax deduction for not for profit organizations.</p>

      <p> The organization name will appear as the description on your bank statement.</p>
      <p>Please keep a personal record of this transaction in order to recognize it on your bank statement. Campaigns can run for as little as one week, so If you dispute the transaction as fraudulent, general, or "other" charge after 10 days of your initial charge, you will not be refunded due to the fact we must pay the organization their donated campaign funds immediately after the campaign ends.</p>
        
      <p>If you have any questions, visit our website @ <strong class="web"><a class="text-and-link " href="https://teamfunded.com" target="_blank">www.teamfunded.com</a></strong>, contact us at <strong class="web">info@teamfunded.com</strong> or call at +1 281-852-5955</p>
    </div>
  <div class="col-12 md:col-12 sm:col-12 lg:col-12">
    </div>
</div>
