import {
  Component,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { UserCommonService } from '../../../shared/services/user-common.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { CommonMessageTransferService } from '../../../shared/services/common-message-transfer.service';
import { ErrorDto } from '../../../shared/models/error-dto';
import { Message } from 'primeng/api';
declare var stripe: any;
declare var elements: any;

@Component({
  selector: 'app-payment-stripe',
  templateUrl: './payment-stripe.component.html',
  styleUrls: ['./payment-stripe.component.scss']
})
export class PaymentStripeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cardInfo')
  cardInfo!: ElementRef;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: any;
  payment: any;
  userId : any;
  campaignId : any;
  mediaType : any;
  paymentProcessing = false;
  errorMessage :any;
  paymentFailed = false;
  restAPIGeneralSubscription: any;
  serviceCharges: any;
  totalAmount: any;
  subTotalAmount: any;
  refereId: any;
  // The items the customer wants to buy
  elements;
  message: Message[] = [];

  prButton: any;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private commonMessageTransferService: CommonMessageTransferService,
    private commonBindingService: CommonBindingDataService,
    private commonService: UserCommonService) {
      this.serviceCharges = this.route.snapshot.queryParamMap.get('serviceCharges');
    }

  ngOnInit() {
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.campaignId = this.route.snapshot.params['c'];
    this.mediaType = this.route.snapshot.queryParamMap.get('mediaType');
    this.errorMessage = this.commonBindingService.getLabel('err_general_msg');
    if (this.commonService.donor) {
      this.payment = this.commonService.donor;
      this.subTotalAmount = parseFloat(this.payment.amount) + parseFloat(this.serviceCharges);
      this.totalAmount = parseFloat(this.subTotalAmount).toFixed(2);
    }
    document.querySelector("#payment-form").addEventListener("submit", (event) => this.handleSubmit(event, this.campaignId));

    this.restAPIGeneralSubscription = this.commonMessageTransferService.restAPIGeneralErrorEvent
      .subscribe((error: ErrorDto) => {
        console.log('Got general error', error);
        this.paymentProcessing = false;
        this.errorMessage = error.message;
        this.paymentFailed = true;
      });
    this.processRefereId();  
  }

  async handleSubmit(e, campaignId) {
    e.preventDefault();
    document.querySelector('#submit').setAttribute('disabled', 'disabled');
    await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: '/donation/' + this.campaignId
      },
      redirect: 'if_required'
    }).then((result)=> {
      document.querySelector('#submit').removeAttribute('disabled');
      if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
        console.log('success');
        this.makePaymentCall('', result.paymentIntent);
      }else if(result.paymentIntent && result.paymentIntent.status === "processing") {
        alert('Your payment is processing.');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }else {
        if (result.error.type === "card_error" || result.error.type === "validation_error") {
          this.message.push({severity: 'Error', summary: 'Error', detail: result.error.message });
          this.errorMessage = result.error.message;
        } else {
          this.message.push({severity: 'Error', summary: 'Error', detail: this.commonBindingService.getLabel('lbl_unexpected_error')});
          this.errorMessage = this.commonBindingService.getLabel('lbl_unexpected_error');
        }
        this.paymentFailed = true;
      }
    });
    
  }
  
  // Fetches a payment intent and captures the client secret
async initialize() {
  let that = this;
  let payload = {'amount': this.totalAmount};
  this.commonService.getPaymentIntent(payload).subscribe((res)=> {
    const clientSecret = res.paymentIntent;
    const appearance = {
      theme: 'stripe',
    };
    elements = stripe.elements({ appearance, clientSecret });
  
    const paymentElementOptions = {
      layout: "tabs",
    };
  
    const paymentElement = elements.create("payment", {
      paymentElementOptions
    });
    paymentElement.mount("#payment-element");

    paymentElement.on('change', function(event) {
      that.paymentFailed = false;
    });
  }, (error)=> {
      console.log(error);
      this.router.navigate(['/donation/' + this.campaignId], {
        queryParams: {
          userId: this.userId,
          mediaType: this.mediaType
        }
      });
  });
}

  ngAfterViewInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.restAPIGeneralSubscription?.unsubscribe();
  }

  onChange({ ...error }) {
    if (error) {
      this.error = error['message'];
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
    console.log("change")
  }

  makePaymentCall(stripeTokenObj?:any, paymentIntent?) {
    const payload = {
      refereeUserId: this.route.snapshot.queryParamMap.get('userId'),
      campaignId: this.campaignId,
      mediaType: this.mediaType,
      amount: this.payment.amount,
      serviceFee: this.serviceCharges,
      donorName: this.payment.name,
      donorMessage: this.payment.message,
      donorEmail: this.payment.email,
      refererUserId: this.refereId,
      applePayResultStatus: paymentIntent?.status,
      paymentIntentId: paymentIntent?.id,
      payment_method: paymentIntent?.payment_method
    };

    this.commonService.doPayment(payload).subscribe(results => {
      if (results.id) {
        if(results.donorEmail !== '' || results.donorName !== 'Anonymous'){
          window.open('/receipt/' + results.id, '_blank');
        }
        this.router.navigate(['/success/' + this.campaignId], {
          queryParams: {
            transactionId: results.transactionId,
            userId: this.userId,
            campaignId: this.campaignId,
            mediaType: this.mediaType
          }
        });
      }
    }, (error) => {
      this.paymentProcessing = false;
      // this.errorMessage = error.erros.general[0].message;
      this.paymentFailed = true;
    });
  }

  direct(){
    
  }

  processRefereId() {
    const currentUser = localStorage.getItem("currentUser");
    if(currentUser) {
      let result = currentUser.match(/.{1,16}/g)
      let revFirst = result[0].split("").reverse().join("");
      let revSecond = result[1].split("").reverse().join("");
      this.refereId = (revFirst + revSecond);
    }else {
      this.router.navigate(['/donation/' + this.campaignId], {
            queryParams: {
              userId: this.userId,
              mediaType: this.mediaType
            }
      });
    }
  }

}
