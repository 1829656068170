import { Injectable } from '@angular/core';
import { RestApiService } from '../services/rest-api.service';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.settings';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(private restApi: RestApiService) {

  }

  getUser(userId): Observable<any> {
    return this.restApi.get('get user', 'public/users/' + userId, 'page-center');
  }

}
