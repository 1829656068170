import { Injectable } from '@angular/core';
import { RestApiService } from '../services/rest-api.service';
import { Observable } from 'rxjs';

@Injectable()
export class UserCommonService {
  donor: any;
  constructor(private restApi: RestApiService) { }


  getUser(userId:any): Observable<any> {
    return this.restApi.get('get user', 'public/users/' + userId, 'page-center');
  }

  getCampaign(campaignId:any): Observable<any> {
    return this.restApi.get('get user', 'public/campaign/' + campaignId, 'page-center');
  }

  doPayment(payload :any): Observable<any> {
    return this.restApi.post('get user', 'public/donations', payload, 'page-center');
  }

  getDonors(campaignId: any, date: any): Observable<any> {
    return this.restApi.get('student-details-campaign', `donations?campaignId=${campaignId}&refereeId=&date=${date}`, 'page-center');
  }

  getShareURLs(campaignId: any, userId: any): Observable<any> {
    return this.restApi.get('get-shortner-url-for-sharing', `public/dynamic-url?userId=${userId}&campaignId=${campaignId}`, 'page-center');
  }

  getReceipt(id:any): Observable<any> {
    return this.restApi.get('get receipt', `public/donations/${id}/receipt`, 'page-center');
  }

  getPaymentIntent(payload): Observable<any> {
    return this.restApi.post('', `public/donations/apple-pay/payment-intent`, payload, 'page-center');
  }

}
