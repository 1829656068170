import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserCommonService } from '../../../shared/services/user-common.service';
import { CommonBindingDataService } from 'src/app/modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  transactionId :any;
  userId: any;
  campaignId: any;
  mediaType: any;
  paymentStatus: any = true;
  lbl_donation_msg: any = '';
  lbl_congo_thank_msg: any = '';
  transactionImageSrc = '';

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private commonService: UserCommonService,
    private commonBindingService: CommonBindingDataService) { }

  ngOnInit() {
    this.transactionId = this.route.snapshot.queryParamMap.get('transactionId');
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.campaignId = this.route.snapshot.params['c'];
    this.mediaType = this.route.snapshot.queryParamMap.get('mediaType');
    this.paymentStatus = this.route.snapshot.queryParamMap.get('paymentStatus');
    localStorage.removeItem("currentUser");
    if(this.paymentStatus === "true") {
      this.lbl_congo_thank_msg = this.commonBindingService.getLabel('lbl_congo_thank_msg');
      this.lbl_donation_msg = this.commonBindingService.getLabel('lbl_donation_msg');
      this.transactionImageSrc = 'assets/success-icon.png'
    }else {
      this.lbl_congo_thank_msg = this.commonBindingService.getLabel('lbl_payment_fail_msg');
      this.lbl_donation_msg = this.commonBindingService.getLabel('lbl_donation_fail_msg');
      this.transactionImageSrc = 'assets/warning-icon.png'
    }
  }

  onBack() {
    this.commonService.donor = '';
    this.router.navigate(['/donation/' + this.campaignId], {
      queryParams: {
        userId: this.userId,
        mediaType: this.mediaType
      }
    });
  }

}
