<app-loader></app-loader>
<!-- <div class=""> -->
    <div class="main-panel md:col-12">
        <app-header></app-header>
        <div class="coach-inner-wrap inner-bg">
            <div class="inner-container coach-layout inner-bg">

                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="clearfix">
            <div class="coach-footer">
                <div class="footer grid">
                    <div class=" col-6 lg:col-6 md:col-6 sm:col-12">
                        <p class="copy-text"> Copyright&#9400; 2024 TeamFunded, All rights reserved.</p>
                    </div>
                    <div class="col-6 lg:col-6 md:col-6 sm:col-12 mt-3 privacy-text" align="right">
                        <a class="md:col-12 sm:col-12" href="assets/privacy-policy.pdf" target="_blank" align="">
                            <p class="inline"> Privacy Policies </p>
                        </a>
                        <a class="md:col-12 sm:col-12 ml-10" href="assets/terms-of-services-updated.pdf" target="_blank" align="right">
                            <p class="inline">Terms and Conditions </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <p-growl [(value)]="msgs" life=5000></p-growl> -->
<!-- </div> -->