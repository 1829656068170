import { Component, OnInit } from '@angular/core';
import { UiService } from '../../services/ui.service';
import { Router } from '@angular/router';
import { MenuItem, Message, ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [UiService]
})
export class HeaderComponent implements OnInit {
  notificationInterval :any;
  userFullName: any;
  items: MenuItem[]=[];
  message: Message[] = [];
  changePasswordPopup = false;
  editProfilePopup = false;
  myDate = new Date(1496314866000);
  myDateLong = 1496314866000;
  minuts = 240;
  notifications: any = [{ 'count': 0, 'limit': 5, 'offset': 0, 'sortColumn': null, 'sortType': null, 'searchText': null, 'data': [] }];
  unReadNotificatonsCount = 0;
  limit = 5;
  offset = 0;
  showMoreOff = true;
  corpId:any;

  constructor(
    private uiService: UiService,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {

  }

  ngOnInit() {

    this.items = [
      {
        label: 'Edit Profile', icon: 'fa fa-user-circle-o', command: (event) => {
          this.editProfile();
        }
      },
      {
        label: 'Change password', icon: 'fa  fa-key', command: (event) => {
          this.router.navigate(['admin/change-password']);
        }
      },
      {
        label: 'Logout', icon: 'fa fa-sign-out', command: (event) => {
          this.doSignOut();
        }
      }
    ];

    this.notificationProcessInitialization();

  }

  notificationProcessInitialization() {


  }

  getNotificationUnReadcountCount() {

  }


  getNotificationListAndToggle(event:any, overlaypanel:any) {
    this.limit = 5;
    this.offset = 0;
    this.notifications = [];
    this.getNotificationList();
    overlaypanel.toggle(event);
  }

  getNotificationList() {
    // this.corpId = this.storageService.getItem(AppSettings.CURRENT_USER_CORPORATE_ID);
    // this.NotificationsService.getDetails(this.limit, this.offset, this.corpId).subscribe(notification => {
    //   if (notification.count >= notification.limit) {
    //     this.offset = this.offset + notification.limit;
    //   }
    //   this.notifications = [...this.notifications, ...notification.data];
    //   this.limitReached(notification);
    // });

    this.notifications = {
      'count': 40,
      'limit': 10,
      'offset': 0,
      'sortColumn': '',
      'sortType': '',
      'searchText': '',
      'data': [
        {
          'id': '5ac1a1b215ad48ab84e3e646544109c2',
          'createdAt': 1523451597084,
          'updatedAt': 0,
          'message': 'Jonathan request for profile correction as coach',
          'messageDetails': 'please change coach role to football, name should be John Honro, School S.V.C.S. High School.',
          'notificationSendDateStr': 'Apr 11, 2018 12:59 PM'
        },
        {
          'id': 'a16880f0256144baa90e3ca82f5a04a3',
          'createdAt': 1525555555084,
          'updatedAt': 0,
          'message': 'Rayn request for profile correction as coach',
          'messageDetails': 'please change coach role to football',
          'notificationSendDateStr': 'Apr 11, 2018 05:37 AM'
        },
        {
          'id': 'ae145a230d7547e1860106e5435ab2d0',
          'createdAt': 1515555555084,
          'updatedAt': 0,
          'message': 'Peter request for profile correction as coach',
          'messageDetails': 'please change coach role to football',
          'notificationSendDateStr': 'Apr 11, 2018 05:36 AM'
        },
        {
          'id': '929a09a3ba524de79bc831c0ad427f4a',
          'createdAt': 1523020549249,
          'updatedAt': 0,
          'message': 'Tim request for profile correction as coach',
          'messageDetails': 'please change coach role to football',
          'notificationSendDateStr': 'Apr 06, 2018 01:15 PM'
        },
        {
          'id': 'b969458336d84dfc9c04cc199ed0c8b0',
          'createdAt': 1523019749669,
          'updatedAt': 0,
          'message': 'Sam request for profile correction as coach',
          'messageDetails': 'please change coach role to football',
          'notificationSendDateStr': 'Apr 06, 2018 01:02 PM'
        },
        {
          'id': '53e56c3dc83e406aaab0170149764db5',
          'createdAt': 1523019706338,
          'updatedAt': 0,
          'message': 'Maria request for profile correction as coach',
          'messageDetails': 'please change coach role to football',
          'notificationSendDateStr': 'Apr 06, 2018 01:01 PM'
        },
        {
          'id': '3a20914a1271498f9b81ae12119db900',
          'createdAt': 1523019618210,
          'updatedAt': 0,
          'message': 'Akaria request for profile correction as coach',
          'messageDetails': 'please change coach role to football',
          'notificationSendDateStr': 'Apr 06, 2018 01:00 PM'
        },
        {
          'id': '40612f556abb4a9fa78046bdf78e1a85',
          'createdAt': 1523019011201,
          'updatedAt': 0,
          'message': 'Simthia request for profile correction as coach',
          'messageDetails': 'please change coach role to football',
          'notificationSendDateStr': 'Apr 06, 2018 12:50 PM'
        },
        {
          'id': 'b16408c937864dbeba42a17dfd983c19',
          'createdAt': 1523018917343,
          'updatedAt': 0,
          'message': 'Sinthia request for profile correction as coach',
          'messageDetails': 'please change coach role to football',
          'notificationSendDateStr': 'Apr 06, 2018 12:48 PM'
        },
        {
          'id': '8249b7ee06514bab801603420a9c302e',
          'createdAt': 1523018122683,
          'updatedAt': 0,
          'message': 'Sora request for profile correction as coach',
          'messageDetails': 'please change coach role to football',
          'notificationSendDateStr': 'Apr 06, 2018 12:35 PM'
        }
      ]
    };

    this.unReadNotificatonsCount = this.notifications.data.length;
  }

  limitReached(notification:any) {
    const currentcount = notification.limit + notification.offset;
    if (currentcount >= notification.count) {
      this.showMoreOff = true;
    } else {
      this.showMoreOff = false;
    }
  }

  onSideMenuClick(event: any) {
    event.preventDefault();
    // document.getElementsByTagName('body')[0].classList.toggle('closed');
    this.uiService.sidebarToggled();
  }

  doSignOut() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to perform this action?',
      accept: () => {
        this.router.navigate(['signin']);
      }
    });

  }

  updateProfileContent() {

  }

  changePassword() {
    this.changePasswordPopup = true;
  }

  editProfile() {
    this.editProfilePopup = true;
    this.router.navigate(['admin/profile']);
  }

  onCancelEditProfile(event: any) {
    this.editProfilePopup = !this.editProfilePopup;
  }

  onCancelChangePassword(event: any) {
    this.changePasswordPopup = !this.changePasswordPopup;
  }

  errorMessage(error:any) {
    error = JSON.parse(error);
    this.message = [];
    this.message.push({ severity: 'error', summary: 'error', detail: error.general[0].message });
  }


}

