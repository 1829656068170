<div class="payment-details-parent" *ngIf="payment">
  <div class="ui-g">
    <div class="col-6">
      <p align="left" class="item-name" translate>lbl_contribution</p>
      <h1>  <a class="inline pull-left link-edit" [routerLink]="['/donation']" [queryParams]="{ userId: userId,campaignId:campaignId,mediaType:mediaType}">EDIT</a></h1> 
     <h1> <span class="item-value mrr-10 pull-left">${{payment.amount}}</span></h1>
    </div>
  </div>

  <!-- <form id="paymentForm"
    method="POST"
    action="https://YourServer/PathToExistingPaymentProcessingScript">
    <input type="hidden" name="dataValue" id="dataValue" />
    <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
    <button type="button"
        class="AcceptUI"
        data-billingAddressOptions='{"show":true, "required":false}' 
        data-apiLoginID="YOUR API LOGIN ID" 
        data-clientKey="YOUR PUBLIC CLIENT KEY"
        data-acceptUIFormBtnTxt="Submit" 
        data-acceptUIFormHeaderTxt="Card Information" 
        data-responseHandler="responseHandler">Pay
    </button>
</form> -->
  <div class="payment-details">
    <div class="item-name pay-via-card" translate>lbl_pay_card</div>
    <div class="ui-g">
      <div class="col-8 ui-lg-8 ui-sm-12">
        <form [formGroup]="donationDetailsForm">
          <div class="grid ui-lg-12 ui-md-12 ui-sm-12">
            <div class="form-field ">
              <div class="form-control">
                <h3 class="label mt-13" translate>lbl_enter_credit_no</h3>
                <input type="text" formControlName="cardNumber" placeholder="" class="ui-inputtext" autocomplete="off" pInputText/>
              </div>
            </div>

            <div class="form-errors" *ngIf="donationDetailsForm.controls.cardNumber.errors  && (donationDetailsForm.controls.cardNumber.dirty || donationDetailsForm.controls.cardNumber.touched)">
              <div [hidden]="!donationDetailsForm.controls.cardNumber.errors.required" translate>
                err_card_no_req
              </div>
              <div [hidden]="checkCardValid()" translate>
                err_card_invalid
              </div>
            </div>
          </div>

          <div class="col-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-field">
              <div class="form-control">
                <h3 class="label" translate>err_exp_month</h3>
                <p-dropdown [style]="{'width': '100%'}" [options]="month" formControlName="expiryDate"></p-dropdown>
              </div>
            </div>

            <div class="form-errors" *ngIf="donationDetailsForm.controls.expiryDate.errors  && (donationDetailsForm.controls.expiryDate.dirty || donationDetailsForm.controls.expiryDate.touched)">
              <div [hidden]="!donationDetailsForm.controls.expiryDate.errors.required" translate>
                err_exp_date_req
              </div>
            </div>
          </div>

          <div class="col-4 ui-lg-4 ui-md-4 ui-sm-12">

            <div class="form-field">
              <div class="form-control">
                <h3 class="label" translate>lbl_expiry_year</h3>
                <p-dropdown [style]="{'width': '100%'}" [options]="year" formControlName="expiryYear"></p-dropdown>
              </div>
            </div>

            <div class="form-errors" *ngIf="donationDetailsForm.controls.expiryYear.errors  && (donationDetailsForm.controls.expiryYear.dirty || donationDetailsForm.controls.expiryYear.touched)">
              <div [hidden]="!donationDetailsForm.controls.expiryYear.errors.required" translate>
                err_exp_req
              </div>
            </div>
          </div>

          <div class="col-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-field">
              <div class="form-control">
                <h3 class="label" translate>lbl_cvv_Seq_code</h3>
                <input type="password" formControlName="cvv" class="ui-inputtext" autocomplete="off" pInputText/>
              </div>
            </div>
            <div class="form-errors" *ngIf="donationDetailsForm.controls.cvv.errors  && (donationDetailsForm.controls.cvv.dirty || donationDetailsForm.controls.cvv.touched)">
              <div [hidden]="!donationDetailsForm.controls.cvv.errors.required" translate>
                err_cvv_req
              </div>
              <div [hidden]="checkCVVValid()" translate>
                err_cvv_invalid
              </div>
            </div>
          </div>
          <div class="grid">
            <div class="text-center">
              <!-- <button pButton class="theme-btn donate-btn pull-right" label="Pay" [disabled]="!donationDetailsForm.valid" type="submit"
                (click)="onPay()"></button> -->
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

</div>
<!-- <p-growl [(value)]="msgs" life=5000></p-growl> -->