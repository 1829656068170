import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoachShareComponent } from './modules/donation/pages/coach-share/coach-share.component';
import { StudentShareComponent } from './modules/donation/pages/student-share/student-share.component';
import { PaymentStripeComponent } from './modules/donation/pages/payment-stripe/payment-stripe.component';
import { SuccessComponent } from './modules/donation/pages/success/success.component';
import { TermsConditionsComponent } from './modules/donation/pages/terms-conditions/terms-conditions.component';
import { PrivacyPoliciesComponent } from './modules/donation/pages/privacy-policies/privacy-policies.component';
import { PaymentReceiptComponent } from './modules/donation/pages/payment-receipt/payment-receipt.component';

const routes: Routes = [
  { path: 'coach', component: CoachShareComponent },
  { path: 'donation/:c', component: StudentShareComponent },
  { path: 'payment/:c', component: PaymentStripeComponent },
  { path: 'paymentStatus/:c', component: SuccessComponent },

  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'privacy-policies', component: PrivacyPoliciesComponent },
  { path : 'receipt/:c', component: PaymentReceiptComponent}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
