import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from 'express';
import { UserCommonService } from 'src/app/modules/shared/services/user-common.service';

@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.scss']
})
export class PaymentReceiptComponent implements OnInit {
  receiptId: string;
  products = [];
  receiptData: any= {};
  constructor(private route: ActivatedRoute,
    private userCommonService: UserCommonService) { }

  ngOnInit(): void {
    this.receiptId = this.route.snapshot.paramMap.get('c');
    if(this.receiptId){
      this.getReciptDeatisl();
    }
  }

  getReciptDeatisl(){
    this.userCommonService.getReceipt(this.receiptId).subscribe(results => { 
      this.receiptData = results;
      this.products.push(this.receiptData)
    })
  }

}
