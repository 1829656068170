import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';

// import { GrowlModule } from 'primeng/components/growl/growl';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  TranslateModule, TranslateLoader, TranslateService,
  TranslatePipe, TranslateDirective, MissingTranslationHandler
} from '@ngx-translate/core';

import { MiTranslateLoaderService } from './services/mi-translate-loader.service';
import { MiMissingTranslationHandlerService } from './services/mi-missing-translation-handler.service';

import { ProgressBarModule } from 'primeng/progressbar';

import { PanelModule } from 'primeng/panel';


import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';


import { UiService } from './services/ui.service';
import { RestApiService } from './services/rest-api.service';
import { UserCommonService } from './services/user-common.service';
import { StorageService } from './services/storage.service';
import { CommonMessageTransferService } from './services/common-message-transfer.service';
import { CommonBindingDataService } from './services/common-binding-data.service';
import { AuthenticationValidationService } from './services/authentication-validation.service';
import { LoaderService } from './components/loader/loader.service';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ConfirmDialogModule,
    // GrowlModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
    PanelModule,
    TableModule,
    CheckboxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: MiTranslateLoaderService
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MiMissingTranslationHandlerService
      }
    })
  ],
  declarations: [
    LoaderComponent,
    HeaderComponent
  ],
  exports: [
    ConfirmDialogModule,
    LoaderComponent,
    HeaderComponent
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders <SharedModule>{
    return {
      ngModule: SharedModule,
      providers: [
        LoaderService,
        RestApiService,
        UiService,
        UserCommonService,
        StorageService,
        CommonMessageTransferService,
        CommonBindingDataService,
        AuthenticationValidationService,
        CommonMessageTransferService,
        TranslateService
      ]
    };
  }

  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

  }

}
