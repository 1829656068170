<div>
  <div class=" profile-wrapper">
    <div class="img-parent-wrap responsive-container">
      <img src="assets/success-icon.png" class="center-block" alt="" />
      <h1 class="heading text-center mr-zero" translate>lbl_congo_thank_msg</h1>
      <h2 class="text-center mr-zero"> <span translate> lbl_trns_id</span>: {{transactionId}}</h2>
      <div class="grid">     
         <div class="col-2 md:col-2 lg:col-2 sm:col-12">
        </div>
     
      <div class="col-8 md:col-8 lg:col-8 sm:col-12 text-center">
        <p class="para text-center" translate>lbl_donation_msg
        </p>
      </div>
      <div class="col-2">
      </div>
    </div>
  </div>

    <p class="para  mr-zero text-center" translate>lbl_thanku_msg</p>
      <div class="text-center mt-2">
        <button pButton class="theme-btn back-btn" [label]="'lbl_back_donation' | translate" type="submit" (click)="onBack()"></button>
      </div>
  </div>

</div>