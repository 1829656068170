

<div class="stripe-form">
  <!-- Display a payment form -->
  <form id="payment-form" style="padding: 10px; margin: auto;">
    <div id="payment-element">
      <!--Stripe.js injects the Payment Element-->
    </div>
    <button id="submit">
      <div class="spinner hidden" id="spinner"></div>
      <span id="button-text">Pay ${{ totalAmount }}</span>
    </button>
    <div style="text-align: center;" class="mt-25">
      <span class="payment-failed" *ngIf="paymentFailed">
        {{errorMessage}}
      </span>
    </div>
    <div class="font-italic mt-15" translate>lbl_do_not_refresh_page</div>
  </form>
</div>
<p-toast position="top-right"></p-toast>
