import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { SeoTagsModel } from '../models/seo-tags-model';

@Injectable()
export class SeoService {
  constructor(private meta: Meta,
    private titleService: Title) { }

  public generateTags(config: SeoTagsModel) {
    // this.removeAllTags();
    if (config === undefined || config === null) {
      return;
    }
    const g: MetaDefinition[] = new Array<MetaDefinition>();
    if (config.pageTitle !== undefined && config.pageTitle !== null) {
      const ogTitle: MetaDefinition = { name: 'og:title', content: config.pageTitle };
      this.titleService.setTitle(config.pageTitle);
      const title: MetaDefinition = { name: 'title', property: 'og:title', content: config.pageTitle };
      const ogSiteName: MetaDefinition = { name: 'site_name', property: 'og:site_name', content: config.pageTitle };
      g.push(title, ogTitle, ogSiteName);
    }
    if (config.description !== undefined && config.description !== null) {
      const ogDesc: MetaDefinition = { property: 'og:description', content: config.description };
      const desc: MetaDefinition = { name: 'description', content: config.description };
      g.push(desc, ogDesc);
    }
    if (config.image !== undefined && config.image !== null) {

      const image: MetaDefinition = { name: 'image', property: 'og:image', content: config.image };
      const twitterImg: MetaDefinition = { name: 'twitter:image', content: config.image };
      const secureImage: MetaDefinition = { property: 'og:image:secure_url', content: config.image };
      const imageWidth: MetaDefinition = { property: 'og:image:width', content: '600' };
      g.push(image, twitterImg, secureImage, imageWidth);
    }




    if (config.url !== undefined && config.url !== null) {
      const url: MetaDefinition = { name: 'url', property: 'og:url', content: config.url };
      g.push(url);
    }
    const result = this.meta.addTags(g, true);
  }

  private removeAllTags() {
    this.meta.removeTag('title');
    this.meta.removeTag('description');
  }
}
