import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { Message } from 'primeng/api';
import { UserCommonService } from '../../../shared/services/user-common.service';
import { ActivatedRoute } from '@angular/router';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { SeoService } from '../../../shared/services/seo-service';
import { AppSettings } from '../../../shared/app.settings';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { interval } from 'rxjs';

@Component({
  selector: 'app-student-share',
  templateUrl: './student-share.component.html',
  styleUrls: ['./student-share.component.scss']
})
export class StudentShareComponent implements OnInit {
  userId:any;
  campaignId: any;
  user: any;
  donations: any;
  campaign: any;
  msgs: Message[] = [];
  mediaType: any;
  origin: any;
  campaignImg = '';
  isPlatformBrowser: any;
  isPlatformServer: any;

  constructor(@Inject(PLATFORM_ID) platformId: Object,
    private userCommonService: UserCommonService,
    private route: ActivatedRoute,
    private commonBindingService: CommonBindingDataService,
    private seoService: SeoService,
    private sanitizer: DomSanitizer) {
    this.isPlatformBrowser = isPlatformBrowser(platformId);
    this.isPlatformServer = isPlatformServer(platformId);
  }

  ngOnInit() {
    this.campaignId = this.route.snapshot.params['c'];
    if (this.isPlatformServer) {
      this.getCampaignDetails(this.campaignId);
    } else if (this.isPlatformBrowser) {
      window.scroll(0, 0);
      this.origin = window.location.origin;
    }
    if (this.checkUserAndCampaignId()) {
      this.getUserAndCampaign();
    } else {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Link is invalid' });
    }
  }

  getCampaignDetails(campaignId:any) {
    this.userCommonService.getCampaign(this.campaignId).subscribe(campaign => {
      this.campaignImg = this.commonBindingService.buildAwsImgLink(campaign.teamImageId);
      const campaginDetails = {
        pageTitle: campaign.name,
        description: campaign.details,
        image: this.commonBindingService.buildAwsImgLink(campaign.teamImageId),
        url: AppSettings.DONATIONS_BASEPATH + this.campaignId
      };
      this.seoService.generateTags(campaginDetails);
    });


  }

  checkUserAndCampaignId() {
    this.userId = this.route.snapshot.queryParams['userId'];
    this.mediaType = this.route.snapshot.queryParams['mediaType'];
    if (this.campaignId) {
      return true;
    } else {
      return false;
    }
  }

  getUserAndCampaign() {
    this.userCommonService.getUser(this.userId).subscribe(user => {
      this.user = user;
      localStorage.setItem('currentUser',this.user.createdBy);
      for (const studentDetails of this.user.studentDetails) {
        if (studentDetails.campaignId === this.campaignId) {
          this.user.teamName = studentDetails.teamName;
          this.user.teamPosition = studentDetails.teamPosition;
          this.user.jerseyNum = studentDetails.jerseyNum;
        }
        this.user.profileImg = this.commonBindingService.buildAwsImgLink(user.profileImage);
      }


      this.userCommonService.getCampaign(this.campaignId).subscribe(campaign => {
        this.campaign = campaign;

        this.campaign.teamImageId = this.commonBindingService.buildAwsImgLink(campaign.teamImageId);
        if (campaign.teamVideoId) {
          const videoURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + campaign.teamVideoId);
          this.campaign.teamVideoId = videoURL;
        }
        this.campaign.coach.profileImage = this.commonBindingService.buildAwsImgLink(this.campaign.coach.profileImage);
        this.campaign.endDateTime = this.commonBindingService.daysLeft(this.campaign.endDatetime, this.campaign.startDatetime);
        this.campaign.shareURL = AppSettings.DONATIONS_BASEPATH + this.campaignId + '?userId=' + this.userId;


        this.userCommonService.getShareURLs(this.campaignId, this.userId).subscribe(urls => {
          this.campaign.shareURL = urls;
        });

        this.campaign.percent = ((this.campaign.totalAmountRaised / this.campaign.goalAmount) * 100).toFixed(2);
        if (this.campaign.campaignStatus !== 400) {
          this.msgs = [];
          this.msgs.push({
            severity: 'error',
            summary: 'Error Message', detail: 'Campaign is not active. you can\'t donate now'
          });
        }

        const lastDonationDate = moment().add(1, 'd').format('YYYY-MM-DD');
        this.campaign.donors = [];
        this.userCommonService.getDonors(this.campaignId, lastDonationDate).subscribe(donors => {

          this.campaign.donors = donors.records;
          if (donors.records.length > 4) {
            if (this.isPlatformBrowser) {
              const secondsCounter = interval(3000);
              secondsCounter.subscribe(n => {
                const popValue = this.campaign.donors.splice(0, 1);
                this.campaign.donors.push(popValue[0]);
              });
            }
          }
        });

      });
    });

  }

}
