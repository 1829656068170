import { Component, OnInit, Input } from '@angular/core';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html'
})
export class CampaignDetailsComponent implements OnInit {
  @Input() campaign :any;
  @Input() user :any;
  constructor(public commonService: CommonBindingDataService) { }
  profileFunded = 98;
  ngOnInit() { }
}
