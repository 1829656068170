import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import {InputTextModule} from 'primeng/inputtext';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations/';
// import { GrowlModule } from 'primeng/components/growl/growl';
import {DialogModule} from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import { HttpErrorHandler } from './modules/shared/services/http-error-handler.service';
import { StudentShareComponent } from './modules/donation/pages/student-share/student-share.component';
import { TermsConditionsComponent } from './modules/donation/pages/terms-conditions/terms-conditions.component';
import { PrivacyPoliciesComponent } from './modules/donation/pages/privacy-policies/privacy-policies.component';
import { CoachShareComponent } from './modules/donation/pages/coach-share/coach-share.component';
import { PaymentComponent } from './modules/donation/pages/payment/payment.component';
import { SuccessComponent } from './modules/donation/pages/success/success.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CampaignProfileComponent } from './modules/donation/components/campaign-profile/campaign-profile.component';
import { CampaignDetailsComponent } from './modules/donation/components/campaign-details/campaign-details.component';
import { LoaderService } from './modules/shared/components/loader/loader.service';
import { ProgressBarModule } from 'primeng/progressbar';
import { UserCommonService } from './modules/shared/services/user-common.service';
import { RestApiService } from './modules/shared/services/rest-api.service';
import { CommonMessageTransferService } from './modules/shared/services/common-message-transfer.service';
import { StorageService } from './modules/shared/services/storage.service';
import { CommonBindingDataService } from './modules/shared/services/common-binding-data.service';
import { MiTranslateLoaderService } from './modules/shared/services/mi-translate-loader.service';
import { MiMissingTranslationHandlerService } from './modules/shared/services/mi-missing-translation-handler.service';
import { DropdownModule } from 'primeng/dropdown';
import { AuthorizationService } from './modules/shared/services/authorization.service';
import { DonorDetailsFormComponent } from './modules/donation/components/donor-details-form/donor-details-form.component';
import { PaymentStripeComponent } from './modules/donation/pages/payment-stripe/payment-stripe.component';
import { SeoService } from './modules/shared/services/seo-service';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { ShareModule } from '@ngx-share/core';


import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PaymentReceiptComponent } from './modules/donation/pages/payment-receipt/payment-receipt.component';
import { TableModule } from 'primeng/table';
import {ToastModule} from 'primeng/toast';


@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'teamfunded-app' }),
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    HttpClientModule,
    InputTextModule,
    DropdownModule,
    DialogModule,
    SelectButtonModule,
    CheckboxModule,
    // GrowlModule,
    ProgressBarModule,
    AppRoutingModule,
    ConfirmDialogModule,
    SharedModule,
    ShareModule,
    TableModule,
    ToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: MiTranslateLoaderService
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MiMissingTranslationHandlerService
      }
    }),

    // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // and returns simulated server responses.
    // Remove it when a real server is ready to receive requests.
    // HttpClientInMemoryWebApiModule.forRoot(
    //   InMemoryDataService, { dataEncapsulation: false }
    // )
  ],
  declarations: [
    AppComponent,
    StudentShareComponent,
    CoachShareComponent,
    PaymentComponent,
    SuccessComponent,
    CampaignProfileComponent,
    CampaignDetailsComponent,
    DonorDetailsFormComponent,
    TermsConditionsComponent,
    PrivacyPoliciesComponent,
    PaymentStripeComponent,
    PaymentReceiptComponent
  ],
  providers: [HttpErrorHandler, LoaderService,
    ConfirmationService, AuthorizationService, CommonBindingDataService,
    MessageService, LoaderService,
    UserCommonService, StorageService, RestApiService, CommonMessageTransferService, SeoService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
