import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { of, Observable } from 'rxjs';

@Injectable()
export class MiTranslateLoaderService implements TranslateLoader {

  getTranslation(lang: string): Observable<any> {
    return of({
      lbl_head: 'Welcome to TeamFunded',
      lbl_para: 'Now fundraising is easier than ever before.',
      lbl_login: 'Login',
      err_username_req: 'Username is requied',
      lbl_email: 'Email for your donation receipt',
      err_email_req: 'Please enter email',
      lbl_email_address: 'Please enter email address',
      err_email_invalid: 'Invalid email',
      err_email_required: 'Email is required.',
      lbl_pass: 'Password',
      err_password_invalid: 'password is invalid',
      err_password_req: 'Please enter password',
      lbl_forgot_pass: 'Forgot Password',
      lbl_forgot_pass_text: 'Forgot your password?',
      lbl_wrong_info: 'Wrong info…',
      lbl_wrong_para: 'Let us know what went wrong?',
      lbl_message: 'Message',
      err_message_req: 'Message is required',
      lbl_set_password: 'Set your password',
      lbl_new_password: 'New password',
      lbl_confirm_password: 'Confirm password',
      lbl_teamfunded_email: 'Email: teamfunded@dev.com',
      lbl_address: 'Address',
      err_address_req: 'Address is required',
      lbl_review_profile: 'Review your profile',
      lbl_name: 'Name',
      err_name_invalid: 'Name is invalid',
      err_valid_data: ' Please enter valid data',
      err_name_req: 'Name is required',
      err_message_length: 'Max. 250 characters',
      err_max_char: 'Max. 50 characters',
      lbl_contact: 'Contact',
      lbl_contact_no: 'Contact Number',
      err_contact_no_req: 'contact number is required',
      err_contact_no_invalid: 'contact number is invalid',
      lbl_school: 'School',
      lbl_schools: 'Schools',
      err_school_req: 'School is required',
      lbl_position: 'Position',
      lbl_good_continue: 'Looks good! Continue',
      lbl_info_wrong: 'My information is wrong',
      lbl_coach_name: 'Coach Name',
      lbl_funded: 'Funded',
      lbl_global_amount: 'Goal Amount',
      lbl_student_count: '140 Students',
      lbl_students: 'Students',
      lbl_days_left: 'Days Left',
      lbl_review_start_camp: 'Review and Start campaign',
      lbl_prize_tier: 'Prize Tiers',
      lbl_title: 'Title',
      err_title_req: 'Title is required',
      lbl_goal: 'Goal',
      lbl_prizes: 'Prizes',
      lbl_prize: 'Prize',
      lbl_action: 'Action',
      lbl_raised: 'raised',
      lbl_prize_title: 'Prize Title',
      lbl_item_no: 'Item No',
      err_item_no_req: ' Item No is required',
      lbl_add_more_prize: 'Add more prize',
      lbl_save: 'Save',
      lbl_picture: 'Picture',
      lbl_phone: 'Phone',
      err_phone_no_req: 'Phone number is required',
      err_phone_no_invalid: 'Phone number is invalid',
      lbl_coaching_position: 'Coaching Position',
      lbl_submit: 'Submit',
      lbl_add_school: 'Add School',
      lbl_school_name: 'School Name',
      lbl_add_notification: 'Add Notification',
      lbl_add_new_school: 'Add new School',
      lbl_share_with_frnd: 'Share with friends',
      lbl_sel_school: 'Select School',
      lbl_campaigns: 'Campaigns',
      lbl_select_campaign: 'Select Campaign',
      err_campaign_req: 'campaign is required',
      lbl_sel_student: 'Select Student',
      err_student_req: 'Student is required',
      err_notifcn_status_req: 'Notify status  is required',
      err_rel_date_req: 'Release date is required',
      lbl_save_notify: 'Save & Notify Later',
      lbl_prize_won: 'Prize won',
      lbl_sorry_message: 'Sorry! Could not complete your request as this product is selected by students',
      lbl_backto_product_list: 'Back to product list',
      lbl_delete_alert: 'Are you sure you want to delete product',
      lbl_shares: 'shares',
      lbl_create_date: 'Created DATE',
      lbl_notificn_date: 'Notification DATE',
      lbl_status: 'Status',
      lbl_notify: 'Notify Now',
      lbl_free_pass: 'We are giving 15 free passes of Super Bowl to lucky users' +
        'who have raised more than $15,000 in last one year We are giving' +
        '15 free passes of Super Bowl to lucky users, who have raised more than $15,000 in last one year',
      lbl_download_list: 'Download List',
      lbl_add_campaign: 'Add Campaign',
      lbl_closed: 'Closed',
      lbl_ended: 'Ended',
      lbl_yetto_start: 'Yet to start',
      lbl_15_days_left: '15 Days Left',
      lbl_under_admin_review: 'Under Admin Review',
      lbl_under_coach_review: 'Under Coach Review',
      lbl_active_campaigns: 'Active Campaigns',
      lbl_inactive_campaigns: 'Inactive Campaigns',
      lbl_no_campaigns: 'You Dont have any Campaigns',
      lbl_congo_campaign_start_msg: 'Congratulations your campaign is ready to start',
      lbl_review_details_msg: 'Please review all details before starting the campaign, Contact us if any details need to be changed.',
      lbl_start_campaign: 'Start Campaign',
      lbl_student_involved: 'Students Involved',
      lbl_copyright: 'Copyright',
      lbl_copy_text: '2023 TeamFunded, All rights reserved.',
      lbl_privacy_policy: 'Privacy Policies',
      lbl_terms_conditions: 'Terms and Conditions',
      lbl_active: 'Active',
      lbl_days_ago: 'days ago',
      lbl_show_more: 'Show More',
      lbl_Raised: 'Raised',
      lbl_review_campaign: 'Review campaign..',
      lbl_campaign_details: 'Campaign Details',
      err_camp_details_req: '  campaign details is required',
      lbl_campaign_title: 'Campaign Title',
      lbl_team_name: 'Team Name',
      err_team_name_req: ' team name is required',
      lbl_team_picture: 'Team Picture',
      err_team_picture_req: 'team picture is required',
      lbl_team_video: 'Team Video',
      lbl_upload_video: 'Upload Video',
      lbl_campaign_goal: 'Campaign Goal ',
      err_goal_req: ' goal is required',
      lbl_campaign_timeframe: 'Campaign Timeframe',
      lbl_timeframe_req: ' timeframe is required',
      lbl_looks_good: 'Looks good',
      lbl_next: 'Next',
      lbl_review_student_details: 'Review Student Details...',
      lbl_student_details: 'Student Details',
      lbl_mobile: 'Mobile',
      lbl_mobile_no_req: ' mobile number is required',
      lbl_team_position: 'Team position',
      err_team_position_req: 'team position is required',
      lbl_jersy_no: 'Jersy no.',
      err_jersy_no_req: 'jersey no. is required',
      err_coaching_position_req: 'coaching position is required',
      lbl_add_more_student: 'Add more students',
      lbl_upload_csv_student_data: 'Upload CSV of student details',
      lbl_csv_alert: 'Make sure your CSV is in correct format',
      lbl_dwnload_csv: 'Download Sample CSV from here',
      lbl_chng_password: 'Change Password',
      lbl_old_password: 'Old password',
      err_old_password_req: ' Old password is required',
      lbl_add_coach: 'Add Coach',
      lbl_coach: 'Organizer',
      lbl_notification: 'Notifications',
      lbl_no_notification_found: 'No notification found',
      lbl_edit_profile: 'Edit Profile',
      lbl_campaign: 'campaign',
      lbl_baseball_team: 'Baseball team',
      lbl_silicoln_wrist_bands: 'Silicoln Wrist Bands',
      lbl_in_donation: 'in donations',
      lbl_football_team_fundraiser: 'Football Team Fundraiser - 2021',
      lbl_teams: 'Teams',
      lbl_search_by_student: 'Search by student name...',
      lbl_search_campaign: 'Search campaign..',
      lbl_notification_date: 'Notification Date',
      lbl_search_school: 'Search school...',
      lbl_search_student: 'Search student...',
      lbl_brief_desc_msg: 'Write a brief description of your campaign including some' +
        'of the improvements you will make if you reach your goals',
      lbl_select_coach: 'Select Coach',
      lbl_number_of_days: 'Number of days',
      lbl_Search_coach: 'Search coach...',
      lbl_deactivate: 'Deactivate',
      lbl_edit_students: 'Edit students',
      lbl_notify_later: 'Notify Later',
      lbl_create_new_campaign: 'Create New Campaign',
      lbl_create_campaign: 'Create Campaign',
      lbl_delete_product: 'Delete Product',
      lbl_show_older: 'Show Older',
      lbl_uplaod_student_info: 'Upload Student Information',
      lbl_help_msg: 'We need your help! Please donate to our team and share the campaign with your friends and family.',
      lbl_choose_amout: 'Choose amount',
      lbl_custom_amount: 'Custom amount',
      lbl_anonymous: 'Anonymous',
      err_amount_req: 'Amount is required',
      err_amount_invalid: 'Amount is Invalid',
      err_custom_amount_invalid: 'Decimal values are not allowed',
      lbl_contribution: 'Your Contribution',
      lbl_pay_card: 'Pay via card',
      lbl_enter_credit_no: 'Enter Credit Card Number',
      err_card_no_req: 'Card number is required',
      err_card_invalid: 'Card number is Invalid',
      err_exp_month: 'Expiry Month',
      err_exp_date_req: 'Expiry month is required',
      lbl_expiry_year: 'Expiry Year',
      err_exp_req: 'Expiry year is required',
      lbl_cvv_Seq_code: 'CVV / Security Code',
      err_cvv_req: ' CVV is required',
      err_cvv_invalid: 'CVV is invalid',
      lbl_congo_thank_msg: 'Congratulations and THANK YOU!',
      lbl_payment_fail_msg: 'Payment Processing Delay',
      lbl_trns_id: 'Transaction Id',
      lbl_donation_msg: `Thank you for your support! Your donations will be added to the campaign goal. Please share the campaign with all your contacts to help the organization reach or beat their goal.`,
      lbl_donation_fail_msg: 'We are currently experiencing a delay in processing your payment. We recommend checking with your bank to confirm the transaction status.',
      lbl_thanku_msg: ' Thank you for your help!',
      lbl_back_donation: 'Continue to Share!',
      lbl_credit_card_info: 'Credit Card Info',
      err_general_msg: 'Payment failed, please try again.',
      err_invaid_amount: 'Donation amount is invalid',
      err_summary: 'Error Message',
      lbl_video_title: 'Press play below to watch our team video!',
      lbl_thanks_donors: 'Thank you to our Donors!',
      lbl_additional_charges: 'Cover the transaction fees:',
      lbl_total_donation: 'Total donation amount:',
      lbl_cover_fees: 'Please help us cover our transaction fees',
      lbl_unexpected_error: 'An unexpected error occurred.',
      lbl_do_not_refresh_page: 'Do not refresh the page. If you refresh the page, you will be redirected back to the previous page. Thank you for your patience!'
    });
  }
}

