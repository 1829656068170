import { Component, OnInit, OnChanges } from '@angular/core';
import { UserCommonService } from '../../../shared/services/user-common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppSettings } from '../../../shared/app.settings';
import { Message } from 'primeng//api';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnChanges {
  userId: any;
  campaignId: any;
  donationDetailsForm: any;
  payment: any;
  msgs: Message[] = [];
  mediaType :any;
  month = [{
    label: 'Select month',
    value: ''
  }, {
    label: '01',
    value: '01'
  }, {
    label: '02',
    value: '02',
  }, {
    label: '03',
    value: '03',
  }, {
    label: '04',
    value: '04',
  }, {
    label: '05',
    value: '05',
  }, {
    label: '06',
    value: '06',
  }, {
    label: '07',
    value: '07',
  }, {
    label: '08',
    value: '08',
  }, {
    label: '09',
    value: '09',
  }, {
    label: '10',
    value: '10',
  }, {
    label: '11',
    value: '11',
  }, {
    label: '12',
    value: '12',
  }
  ];

  year :any[]= [];

  constructor(private commonService: UserCommonService, private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
  ) { }

  ngOnChanges(): void {
    this.payment = this.commonService.donor;
  }

  ngOnInit() {
    // window.scrollTo(0, 0);
    this.payment = this.commonService.donor;
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.campaignId = this.route.snapshot.queryParamMap.get('campaignId');
    this.mediaType = this.route.snapshot.queryParamMap.get('mediaType');
    if (this.commonService.donor) {

    } else {
      this.router.navigate(['/donation'], {
        queryParams: {
          userId: this.userId,
          campaignId: this.campaignId,
          mediaType: this.mediaType
        }
      });
    }


    let currentYear = new Date().getFullYear();
    this.year = [{
      label: 'Select year',
      value: ''
    }];

    for (let i = 0; i < 25; i++) {
      this.year.push({ label: currentYear, value: currentYear });
      currentYear = currentYear + 1;
    }

    this.donationDetailsForm = this.formBuilder.group({
      cvv: ['', [Validators.required, Validators.minLength(3),
      Validators.maxLength(4), Validators.pattern(AppSettings.CVV)]],
      cardNumber: ['', [Validators.required, Validators.minLength(13),
      Validators.maxLength(16)]],
      expiryDate: ['', [Validators.required]],
      expiryYear: ['', [Validators.required]],
    });

  }

  checkCVVValid() {
    return (!this.donationDetailsForm.controls.cvv.errors.pattern &&
      !this.donationDetailsForm.controls.cvv.errors.minlength && !this.donationDetailsForm.controls.cvv.errors.maxlength);
  }

  checkCardValid() {
    return (!this.donationDetailsForm.controls.cardNumber.errors.pattern &&
      !this.donationDetailsForm.controls.cardNumber.errors.minlength && !this.donationDetailsForm.controls.cardNumber.errors.maxlength);
  }

  onPay() {
    const payload = {
      refereeUserId: this.route.snapshot.queryParamMap.get('userId'),
      campaignId: this.route.snapshot.queryParamMap.get('campaignId'),
      mediaType: this.mediaType,
      amount: this.payment.amount,
      donorName: this.payment.name,
      donorMessage: this.payment.message,
      donorEmail: this.payment.email,
      creditCardNumber: this.donationDetailsForm.controls.cardNumber.value,
      expiryMonth: this.donationDetailsForm.controls.expiryDate.value,
      expiryYear: this.getCardYear(),
      cvv: this.donationDetailsForm.controls.cvv.value,
    };

    this.commonService.doPayment(payload).subscribe(results => {

      if (results.id) {
        this.router.navigate(['/paymentStatus'], {
          queryParams: {
            transactionId: results.transactionId,
            userId: this.userId,
            campaignId: this.campaignId,
            mediaType: this.mediaType,
            paymentStatus: true
          }
        });
      }
    }, (error) => {
      this.router.navigate(['/paymentStatus/' + this.campaignId], {
        queryParams: {
          userId: this.userId,
          campaignId: this.campaignId,
          mediaType: this.mediaType,
          paymentStatus: false
        }
      });
    });
  }

  getCardYear() {
    const yearValue = this.donationDetailsForm.controls.expiryYear.value.toString();
    return yearValue.valueOf(yearValue).substring(2);
  }


}
