import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  msgs: Message[] = [];
  constructor(private messageService: MessageService,
    private meta: Meta) {
  }
}
